import './App.css';
import instaLogo from './assets/insta-logo.png'
import linkedinLogo from './assets/linkedin-logo.png'
import githubLogo from './assets/github-logo.png'
import me from './assets/me.png'
import whatsapp from './assets/whatsapp.svg'

const name = 'Sean Conrad'
const title = 'Front End Developer'
const company = 'McKinsey & Company'
const whatsAppNumber = '14807996359'
const gitHubUserName = 'seanconrad1'
const linkedInID = 'sean-conrad'
const instagramUserName = 'seanraad'

const instagramDeepLink = `https://instagram.com/${instagramUserName}`; 
const instagramURL = `instagram://user?username=${instagramUserName}`; 

const getURL = () => {
	if( navigator.userAgent.match(/Android/i) ) {
		// use Android's redirect
		return instagramDeepLink; 
	} else if(navigator.userAgent.match(/iPhone/i) ) {
		// use iOS redirect
		return instagramURL
	} else{
		return instagramDeepLink;
	}
}

const links = [
	{name: 'Instagram', icon: instaLogo, url: getURL() },
	{name: 'LinkedIn', icon: linkedinLogo, url: `https://www.linkedin.com/in/${linkedInID}/`},
	{name: 'GitHub', icon: githubLogo, url: `https://www.github.com/${gitHubUserName}`},
	{name: 'Whatsapp', icon: whatsapp, url: `https://wa.me/${whatsAppNumber}`},
]


function App() {
  return (
    <div className="App">
			<div className='whole-top'>
				<div className='banner'>
					<div className='img-wrapper'>
						<img className='me' src={me} alt="me"></img>
					</div>
				</div>
				
				<div className='top-area'>
					<div className='name-and-bio'>
						<div className='name'>{name}</div>
						<div className='title-and-company'>
							<div className='title'>{title}</div>
							<div className='company'>{company}</div>
						</div>
					</div>
				</div>
			</div>

			<div className='links-container'>
				{links.map(link =>(
					<a key={link.url} className='link-container' href={link.url} target="_blank" rel="noreferrer">
						<div className='logo-and-link' >
							<img className='logo' src={link.icon} alt=""></img>
								<div className='link-name'>{link.name}</div>
						</div>
					</a>
				))}
			</div>
    </div>
  );
}

export default App;
